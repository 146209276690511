import React, { useEffect, useState } from 'react';

import Requirement from './requirement';
import RequirementGroup from './requirement-group';
import HistoryTable from './history-table';

import { ReactComponent as IconChevron } from '../../../assets/chevron-down.svg';

import { isFavorite, meetsRequirements, setFavorite, formatPoeng, formatPoengText } from '../../../utils/studies';
import { passFail } from '../../../utils/study-logic';
import { Button } from '@utdanningno/designsystem-react';

import './styles.scss';


export default function Study({
  study,
  requirements,
  points,
  above22,
  showOnlyMatches,
  className,
}) {

  const { title, cdm_id, org, requires, first, ordinary, offer } = study;
  const firstPoeng = formatPoeng(first);
  const firstPoengText = formatPoengText(firstPoeng);
  const ordinaryPoeng = formatPoeng(ordinary);
  const ordinaryPoengText = formatPoengText(ordinaryPoeng);

  const [isOpen, setIsOpen] = useState(false);
  const classIsOpen = isOpen ? 'is-open' : '';

  const [isFav, setIsFav] = useState(false);

  useEffect(() => {
    setIsFav(isFavorite(cdm_id));
  },
  [cdm_id]);

  const match = meetsRequirements(study, requirements.study, points);
  const okPoints = (above22 ? match[2] : (match[1] || match[2]));
  const okAll = match[0] && okPoints;

  if (!okAll && showOnlyMatches) {
    return null;
  }

  const [txtAll, txtReq, txtFirst, txtOrdin] = [okAll, ...match].map(passFail);
  let textResult = 'Vi kan ikke fastslå om du oppfyller kravene til å komme inn';

  if (match && match[0]) { // Oppfylt fagkrav
    if (okPoints) { // Oppfylt poengkrav
      textResult = 'Du oppfyller fagkravet, og har nok poeng til å komme inn på forrige poenggrense. Poenggrensen kan endre seg fra år til år.';
    }
    else if (okPoints === null) { // Vi kan ikke si om poengkravet er oppfylt
      textResult = 'Du oppfyller fagkravet, men vi kan ikke fastslå om du har nok poeng til å komme inn på forrige poenggrense.';
    }
    else {// Ikke oppfylt poengkrav
      textResult = 'Du oppfyller fagkravet, men har ikke nok poeng til å komme inn på forrige poenggrense. Poenggrensen kan endre seg fra år til år.';
    }
  }
  else if (okPoints) { // Ikke oppfylt fagkrav, men oppfylt poengkrav
    textResult = 'Du oppfyller ikke fagkravet, men har nok poeng til å komme inn på forrige poenggrense. Poenggrensen kan endre seg fra år til år.';
  }
  else if (okPoints === null) {
    textResult = 'Du oppfyller ikke fagkravet, og vi kan ikke fastslå om du har nok poeng til å komme inn på forrige poenggrense.';
  }
  else { // Ikke oppfylt fagkrav og ikke oppfylt fagkrav
    textResult = 'Du oppfyller ikke fagkravet, og har ikke nok poeng til å komme inn på forrige poenggrense. Poenggrensen kan endre seg fra år til år.';
  }

  function handleFavorite(event) {
    event.stopPropagation();
    setFavorite(cdm_id, !isFav);
    setIsFav(!isFav);
  }

  function stopPropagation(event) {
    event.stopPropagation();
  }

  const regexOfferUrl = /(utdanning\/)(\w+\.\w+\/)(.+)/;
  return (
    <div
      className={ ['study', classIsOpen, className].join(' ').trim() }
      onClick={ () => setIsOpen(!isOpen) }
    >
      <div className="study__header">
        <button
          className="study__toggle-open"
          type="button"
        >
          <h3 className="study__title">{title} <IconChevron className="icon" /></h3>

        </button>
        <p className="study__university">{org}</p>
        <ul className="study__link list-reset">
          {offer
            ? <li>
              <a
                href={ `https://utdanning.no${offer}` }
                target="_blank"
                rel="noopener noreferrer"
                onClick={ stopPropagation }
              >
                Les mer om {title}
              </a>
            </li>
            : null}
          <li>
            <Button onClick={ handleFavorite } >
              {isFav
                ? 'Lagret i favoritter'
                : 'Lagre i favoritter'}
            </Button>
          </li>
        </ul>
        <Requirement className="requirement--accepted" text={ textResult } type={ txtAll } />
      </div>
      {isOpen &&
        <div className="study__body">
          <div className="study__requirements">
            <Requirement text={ firstPoeng } label="Skolepoeng" type={ txtFirst } />
            <Requirement text={ ordinaryPoeng } label="Konkurransepoeng" type={ txtOrdin } />
            <Requirement text={ requires } label="Kravkode" type={ txtReq } />
          </div>
          <h4 className="study__title-2">Poenggrenser</h4>

          <Requirement text="" label="Siste poenggrenser på dette studiet:">
            {!above22 &&
              <>
                <div>
                  {typeof firstPoeng === 'number'
                    ? <span>{firstPoeng} skolepoeng</span>
                    : <span>{firstPoengText} <span className="text-dark-gray-2">(skolepoeng)</span></span>
                  }
                </div>
              </>
            }
            <div>
              {typeof ordinaryPoeng === 'number'
                ? <span>{ordinary} konkurransepoeng</span>
                : <span>{ordinaryPoengText} <span className="text-dark-gray-2">(konkurransepoeng)</span></span>
              }
            </div>
          </Requirement>

          {!above22 ?
            <>
              <Requirement text={ `${points.school} skolepoeng` } label="Dine poeng:" type={ txtFirst } />
              <Requirement text={ `${points.total} konkurransepoeng` } type={ txtOrdin } />
            </> :
            <>
              <Requirement text={ `${points.total} konkurransepoeng` } label="Dine poeng:" type={ txtOrdin } />
            </>
          }
          <HistoryTable studyId={ study.id } />


          <RequirementGroup code={ requires } requirements={ requirements } />

          {offer &&
            <>
              <h4 className="study__title-2">Les mer om lærestedet</h4>
              <p className="study__university">
                <a
                  href={ `https://utdanning.no${offer.replace(regexOfferUrl, 'org/$2')}` }
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={ stopPropagation }
                >{org}</a>
              </p>
            </>
          }
        </div>
      }
    </div>
  );
}
